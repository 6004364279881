import 'bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox.min.js';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        let dataset = this.element.dataset;

        const wording = {
            nonSelectedListLabel: 'Choix',
            selectedListLabel: 'Sélection',
            filterPlaceHolder: 'Filtrer la liste',
            moveAllLabel: 'Tout sélectionner',
            removeAllLabel: 'Tout désélectionner',
            infoTextEmpty: 'Vide',
            filterTextClear: 'Voir tout',
        };

        console.log(dataset);

        $('#' + this.element.id).bootstrapDualListbox({
            nonSelectedListLabel: wording.nonSelectedListLabel,
            selectedListLabel: wording.selectedListLabel,
            filterTextClear: wording.filterTextClear,
            filterPlaceHolder: wording.filterPlaceHolder,
            moveAllLabel: wording.moveAllLabel,
            removeAllLabel: wording.removeAllLabel,
            infoTextEmpty: wording.infoTextEmpty,
        });
    }
}
