/**
 * @see https://datatables.net/manual/
 */
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import DateTime from 'datatables.net-datetime';
import 'datatables.net-responsive-bs5';
import 'datatables.net-scroller-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.setAttribute(
      'class',
      'table table-sm table-striped table-hover align-middle order-column'
    );
    this.element.setAttribute('role', 'grid');

    // Options par défaut
    const options = {
      retrieve: true,
      responsive: true,
      order: [[0, 'asc']],
      paging: false,
      lengthChange: false,
      searching: false,
      stateSave: false,
      ordering: true,
      info: true,
      language: {
        decimal: ',',
        thousands: ' ',
        emptyTable:
          '<small class="text-danger"><strong>Aucun enregistrement à afficher</strong></small>',
        sInfoEmpty: '',
        sInfo:
          "<small>Page _PAGE_ sur _PAGES_. Affichage de l'élément _START_ à _END_ sur <strong>_TOTAL_</strong> éléments.</small>",
        sInfoFiltered:
          "<small><em style='color: #FF0000;'>(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)</em></small>",
        sProcessing: 'Traitement en cours...',
        sLoadingRecords:
          '<div class="spinner-border text-info spinner-border-sm" role="status"><span class="visually-hidden">Traitement en cours...</span></div>',
      },
    };

    // URL fournit dans les dataset
    if (this.element.dataset.ajax) {
      // Définition colonnes
      options.columns = [];
      this.element.querySelectorAll('th').forEach((e) => {
        if (e.dataset.field) {
          options.columns.push({ data: e.dataset.field });
        } else if (e.dataset.link) {
          options.columns.push({
            data: 'id',
            render: (data, type) => {
              if (type === 'display') {
                let link = e.dataset.link.replace('0', data);
                return (
                  '<a href="' +
                  link +
                  '" class="btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></a>'
                );
              }
              return data;
            },
          });
        }
      });
    }

    // Ajout des options selon le type
    if (
      this.element.dataset.type == 'std' ||
      this.element.dataset.type == 'btn' ||
      this.element.dataset.type == 'pane'
    ) {
      // Pagination
      options.paging = true;
      options.pageLength = 10;
      options.lengthChange = true;
      options.pagingType = 'full_numbers';
      options.language.lengthMenu = 'Voir _MENU_';
      options.language.paginate = {
        first: "<i class='bi bi-chevron-bar-left' title='premier'></i>",
        previous:
          "<i class='bi bi-chevron-compact-left' title='précédent'></i>",
        next: "<i class='bi bi-chevron-compact-right' title='suivant'></i>",
        last: "<i class='bi bi-chevron-bar-right' title='dernier'></i>",
      };

      // Search
      options.searching = true;
      options.language.search = "<i class='bi bi-search text-success'></i>";
      options.language.sSearchPlaceholder = 'On cherche quoi ?';
      options.language.zeroRecords = 'Rien trouvé !';

      // Buttons
      if (
        this.element.dataset.type == 'btn' ||
        this.element.dataset.type == 'pane'
      ) {
        options.dom = 'Bfrtip';

        options.lengthMenu = [
          [10, 25, 50, -1],
          ['10', '25', '50', 'Tout afficher'],
        ];

        options.language.buttons = {
          pageLength: {
            _: 'Afficher %d éléments',
            '-1': 'Tout afficher',
          },
        };

        options.buttons = [
          {
            text: '<i class="bi bi-arrow-clockwise" title="Recharger"></i>',
            action: (e, dt) => {
              dt.ajax.reload();
            },
          },
          {
            extend: 'copy',
            text: '<i class="bi bi-files"></i>',
          },
          {
            extend: 'csv',
            text: '<i class="bi bi-filetype-csv"></i>',
            header: true,
            fieldSeparator: ';',
          },
          {
            extend: 'excel',
            text: '<i class="bi bi-file-earmark-spreadsheet-fill"></i>',
            autoFilter: true,
          },
          {
            extend: 'print',
            text: '<i class="bi bi-printer-fill"></i>',
          },
          {
            extend: 'pdf',
            text: '<i class="bi bi-file-earmark-pdf text-danger"></i>',
            orientation: 'portrait',
            pageSize: 'A4',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: 15,
          },
          {
            extend: 'pageLength',
          },
        ];
      }

      // Pane
      if (this.element.dataset.type == 'pane') {
        options.language.searchPanes = {
          cascadePanes: true,
          clearMessage: 'Tout nettoyer',
          collapse: {
            0: '<i class="bi bi-funnel"></i>',
            _: '<i class="bi bi-funnel-fill text-warning me-1"></i>(%d)',
          },
        };

        options.buttons.push({
          extend: 'searchPanes',
          text: '<i class="bi bi-funnel"></i>',
          config: {
            cascadePanes: true,
          },
        });
      }
    }

    // Sélection
    if (this.element.dataset.select) {
      options.language.select = {
        rows: {
          _: '<small>%d lignes sélectionnées</small>',
          0: '<small>Cliquer sur une ligne pour la sélectionner</small>',
          1: '<small>1 ligne sélectionnée</small>',
        },
      };

      options.buttons.push({ extend: 'selectAll', text: 'Tout sélectionner' });
      options.buttons.push({
        extend: 'selectNone',
        text: 'Tout désélectionner',
      });

      options.buttons.push({
        extend: 'showSelected',
        text: 'Ne voir que les sélectionnées',
      });

      options.select = {
        style: this.element.dataset.select,
        items: 'row',
        className: 'table-primary',
      };
    }

    $(this.element).DataTable(options);
  }
}
