import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $('.txtb').on('keyup', (e) => {
      // Enter key
      if (e.keyCode == 13 && $('.txtb').val() != '') {
        var task = $("<div class='alert alert-info task'></div>").text($('.txtb').val());

        var del = $("<i class='bi bi-trash3-fill text-danger'></i>").click(function () {
          var p = $(this).parent();
          p.fadeOut(function () {
            p.remove();
          });
        });

        var check = $("<i class='bi bi-check text-success'></i>").click(function () {
          var p = $(this).parent();
          p.fadeOut(function () {
            $('.comp').append(p);
            p.fadeIn();
          });
          $(this).remove();
        });

        task.append(del, check);
        $('.notcomp').append(task);
        $('.txtb').val('');
      }
    });
  }
}
