import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    var calendar = new Calendar(this.element, {
      locale: 'fr',
      timeZone: 'local',
      firstDay: 1,
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'dayGridMonth',
      navLinks: true,
      editable: true,
      selectable: true,
      dayMaxEvents: true,
      weekNumbers: true,
      weekText: 'S',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right:
          'dayGridMonth,listMonth,timeGridWeek,listWeek,timeGridDay,listDay',
      },
      buttonText: {
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        listMonth: 'Liste mois',
        listWeek: 'Liste semaine',
        listDay: 'Liste jour',
      },
      views: {
        timeGridWeek: {
          titleFormat: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },
        },
        listDay: { buttonText: 'list day' },
        listWeek: { buttonText: 'list week' },
        listMonth: { buttonText: 'list month' },
      },
    });
    $.getJSON(window.location.href, function (data) {
      $.each(data, function (key, item) {
        calendar.addEvent(item);
      });
    });
    calendar.render();
  }
}
