/**
 * @see https://datatables.net/manual/
 */
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const options = {
      retrieve: true,
      responsive: true,
      order: [[0, 'asc']],
      paging: true,
      pagingType: 'full_numbers',
      lengthChange: true,
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'Tout'],
      ],
      searching: true,
      stateSave: false,
      ordering: true,
      select: true,
      info: true,
      buttons: [
        {
          text: '<i class="bi bi-arrow-clockwise" title="Recharger"></i>',
          action: (e, dt) => {
            dt.ajax.reload();
          },
        },
        {
          extend: 'copy',
          text: '<i class="bi bi-files"></i>',
        },
        {
          extend: 'csv',
          text: '<i class="bi bi-filetype-csv"></i>',
          header: true,
          fieldSeparator: ';',
        },
        {
          extend: 'excel',
          text: '<i class="bi bi-file-earmark-spreadsheet-fill"></i>',
          autoFilter: true,
        },
        {
          extend: 'print',
          text: '<i class="bi bi-printer-fill"></i>',
        },
        {
          extend: 'pdf',
          text: '<i class="bi bi-file-earmark-pdf text-danger"></i>',
          orientation: 'portrait',
          pageSize: 'A4',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
          pageMargins: 15,
          download: 'open',
        },
        {
          extend: 'pageLength',
        },
        {
          extend: 'selectAll',
          text: '<i class="bi bi-ui-checks" title="Tout sélectionner"></i>',
        },
        {
          extend: 'selectNone',
          text: '<i class="bi bi-dash-square" title="Tout désélectionner"></i>',
        },
        {
          extend: 'searchPanes',
          text: '<i class="bi bi-funnel"></i>',
          config: {
            cascadePanes: true,
          },
        },
        {
          extend: 'searchPanesClear',
          text: '<i class="bi bi-eraser-fill"></i>',
        },
      ],
      language: {
        decimal: ',',
        thousands: ' ',
        emptyTable:
          '<small class="text-danger"><strong>Aucun enregistrement à afficher</strong></small>',
        sInfoEmpty: '',
        sInfo:
          "<small>Page _PAGE_ sur _PAGES_. Affichage de l'élément _START_ à _END_ sur <strong>_TOTAL_</strong> éléments.</small>",
        sInfoFiltered:
          "<small><em style='color: #FF0000;'>(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)</em></small>",
        sProcessing: 'Traitement en cours...',
        sLoadingRecords:
          '<div class="spinner-border text-info spinner-border-sm" role="status"><span class="visually-hidden">Traitement en cours...</span></div>',
        lengthMenu: 'Voir _MENU_',
        search: "<i class='bi bi-search text-success'></i>",
        sSearchPlaceholder: 'On cherche quoi ?',
        zeroRecords: 'Rien trouvé !',
        paginate: {
          first: "<i class='bi bi-chevron-bar-left' title='premier'></i>",
          previous:
            "<i class='bi bi-chevron-compact-left' title='précédent'></i>",
          next: "<i class='bi bi-chevron-compact-right' title='suivant'></i>",
          last: "<i class='bi bi-chevron-bar-right' title='dernier'></i>",
        },
        buttons: {
          pageLength: {
            _: 'Afficher %d éléments',
            '-1': 'Tout afficher',
          },
        },
        searchPanes: {
          cascadePanes: true,
          clearMessage: 'Tout nettoyer',
          collapse: {
            0: '<i class="bi bi-funnel"></i>',
            _: '<i class="bi bi-funnel-fill text-warning me-1"></i>(%d)',
          },
        },
        select: {
          rows: {
            _: '<small>%d lignes sélectionnées</small>',
            0: '<small>Cliquer sur une ligne pour la sélectionner</small>',
            1: '<small>1 ligne sélectionnée</small>',
          },
        },
      },
    };

    if (this.element.dataset.ajax) {
      options.ajax = {
        url: this.element.dataset.ajax,
        dataSrc: 'data',
      };
      options.columns = [];
      this.element.querySelectorAll('th').forEach((e) => {
        if (e.dataset.field) {
          options.columns.push({ data: e.dataset.field });
        } else if (e.dataset.link) {
          options.columns.unshift({
            data: 'id',
            render: (data, type) => {
              if (type === 'display') {
                let link = e.dataset.link.replace('0', data);
                return (
                  '<a href="' +
                  link +
                  '" class="btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></a>'
                );
              }
              return data;
            },
          });
        }
      });
    }

    if (this.element.dataset.routeNew) {
      options.buttons.unshift({
        text: '<i class="bi bi-plus-circle" title="Ajouter un élément"></i>',
        action: () => {
          window.location = this.element.dataset.routeNew;
        },
      });
    }

    let table = $(this.element).DataTable(options);
  }
}
